import axios from 'axios';
import env from 'react-dotenv';

console.log({ env });

const { API_URL, TIMEOUT_DEFAULT } = env;
const applicationId = '9D5644F8-C09E-43BB-8F3D-B1CDE5B2B83A';

function headers({ token }) {
  return {
    Authorization: token ? `Bearer ${token}` : null,
    applicationId,
  };
}

const createInstance = (params, responseType, timeout = TIMEOUT_DEFAULT) =>
  axios.create({
    baseURL: params.baseURL || `${API_URL}`,
    headers: headers(params.headers || {}),
    timeout,
    responseType,
    validateStatus: function (status) {
      return status >= 200 && status < 400;
    },
  });

class APIService {
  static login({ email, password }) {
    return createInstance({}).post('/v1/account/login', {
      email,
      password,
    });
  }

  static getUserData({ token }) {
    return createInstance({ headers: { token } }).get('/v1/account/get');
  }

  static listJobs({ token, enabled, expired, search = '', integrationId = '', areaId = '', pageNumber, pageSize }) {
    let url = `/v1/job/list?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&integrationId=${integrationId}&areaId=${areaId}`;
    if (typeof enabled === 'boolean') {
      url += `&enabled=${enabled}`;
    }
    if (typeof expired === 'boolean') {
      url += `&expired=${expired}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static enabledAllJobs({ token }) {
    return createInstance({ headers: { token } }).post('/v1/job/enable/analyzis');
  }

  static getJob({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/job/get/${id}`,
    );
  }

  static postJob({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      `/v1/job`,
      payload,
    );
  }

  static putJob({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/job/${id}`,
      payload,
    );
  }

  static deleteJob({ token, id }) {
    return createInstance({ headers: { token } }).delete(
      `/v1/job/${id}`,
    );
  }

  static listCandidates({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      `/v1/candidate/search`,
      payload,
    );
  }

  static listJobCandidates({ token, pageNumber, pageSize, id, getCandidates = false, getInterviewed = false }) {
    return createInstance({ headers: { token } }).get(
      `/v1/candidate/job/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}&getCandidates=${getCandidates}&getInterviewed=${getInterviewed}`,
    );
  }

  static getCandidate({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/candidate/get/${id}`,
    );
  }

  static getCandidateCurriculumUrl({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/candidate/curriculum/${id}`,
    );
  }

  static getChatList({ token, pageNumber, pageSize }) {
    return createInstance({ headers: { token } }).get(
      `/v1/chat/list?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
  }

  static getChatMessages({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/chat/messages/${id}`
    );
  }

  static postChatMessage({ token, destinationAccountId, text }) {
    return createInstance({ headers: { token } }).post(
      `/v1/chat/message`,
      { destinationAccountId, text }
    );
  }

  static updateChatLastView({ token, id }) {
    return createInstance({ headers: { token } }).patch(
      `/v1/chat/lastView/${id}`
    );
  }

  static getJobLevels() {
    return createInstance({}).get('/v1/combos/jobLevel');
  }

  static getJobAreas() {
    return createInstance({}).get(`/v1/combos/jobArea`);
  }

  static getCompanySectors() {
    return createInstance({}).get('/v1/combos/companySector');
  }

  static getCompanySizes() {
    return createInstance({}).get('/v1/combos/companySize');
  }

  static getCourseTypes() {
    return createInstance({}).get('/v1/combos/courseType');
  }

  static getCourseStatus() {
    return createInstance({}).get('/v1/combos/courseStatus');
  }

  static getJobIntegrations({ token }) {
    return createInstance({ headers: { token } }).get('/v1/job/list/integrations');
  }

  static getCities({ city, qty }) {
    return createInstance({}).get(`/v1/combos/city/${city}${qty ? `?qty=${qty}` : ''}`);
  }

  static getLanguages({ searchText = '', qty = 10 }) {
    if (searchText.length > 0) {
      return createInstance({}).get(`/v1/combos/language/${searchText}?qty=${qty}`);
    }
    return createInstance({}).get(`/v1/combos/language?qty=${qty}`);
  }

  static getReportJobsAndCandidates({ token }) {
    const timeout = 30000;
    return createInstance({ headers: { token } }, 'blob', timeout).get('/v1/report/jobsAndCandidates');
  }

  static getReportUsers({ token }) {
    const timeout = 60000;
    return createInstance({ headers: { token } }, 'blob', timeout).get('/v1/report/users');
  }

  static getReportIndicatorsUsers({ token, companyId }) {
    const timeout = 60000;
    return createInstance({ headers: { token } }, 'blob', timeout).get(`/v1/report/indicators/${companyId}`);
  }

  static listCompanies({ token }) {
    return createInstance({ headers: { token } }).get('/v1/company/list');
  }

  static listVouchers({ token, used, companyId, email, pageNumber, pageSize }) {
    let url = `/v1/voucher/list?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (used === 'true' || used === 'false') {
      url += `&used=${used === 'true'}`;
    }
    if (companyId) {
      url += `&companyId=${companyId}`;
    }
    if (email) {
      url += `&email=${email}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static getVoucher({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/voucher/getById/${id}`
    );
  }

  static deleteVoucher({ token, id }) {
    return createInstance({ headers: { token } }).delete(
      `/v1/voucher/${id}`
    );
  }

  static postVoucher({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      '/v1/voucher',
      payload,
    );
  }

  static putVoucher({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/voucher/${id}`,
      payload,
    );
  }

  static listCareerContentCategories({ token }) {
    return createInstance({ headers: { token } }).get(
      `/v1/careerContent/categories`,
    );
  }

  static listCareerContent({ token, enabled, search, categoryId, pageNumber, pageSize }) {
    let url = `/v1/careerContent/list/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (enabled) {
      url += `&enabled=${enabled}`;
    }
    if (search) {
      url += `&search=${search}`;
    }
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static getContent({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/careerContent/getById/${id}`,
    );
  }

  static deleteContent({ token, id }) {
    return createInstance({ headers: { token } }).delete(
      `/v1/careerContent/${id}`,
    );
  }

  static postContent({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      `/v1/careerContent`,
      payload
    );
  }

  static putContent({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/careerContent/${id}`,
      payload
    );
  }

  static getContentLikes({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/careerContent/likes/${id}`,
    );
  }

  static getContentFavorites({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/careerContent/favorites/${id}`,
    );
  }

  static postContentUpload({ token, folder, file }) {
    const body = new FormData();
    body.append('file', file);

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, 60000).post(
      `/v1/careerContent/upload?folder=${folder}`,
      body,
    );
  }

  static listAllCompanies({ token, search, pageNumber, pageSize }) {
    let url = `/v1/company/list/all?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (search) {
      url += `&search=${search}`;
    }
    return createInstance({ headers: { token } }).get(url);
  }

  static getCompany({ token, id }) {
    return createInstance({ headers: { token } }).get(
      `/v1/company/getById/${id}`,
    );
  }

  static deleteCompany({ token, id }) {
    return createInstance({ headers: { token } }).delete(
      `/v1/company/${id}`,
    );
  }

  static postCompany({ token, payload }) {
    return createInstance({ headers: { token } }).post(
      `/v1/company`,
      payload
    );
  }

  static putCompany({ token, id, payload }) {
    return createInstance({ headers: { token } }).put(
      `/v1/company/${id}`,
      payload
    );
  }

  static postVoucherImport({ token, companyId, file }) {
    const body = new FormData();
    body.append('file', file);

    return createInstance({ headers: { token, "Content-Type": "multipart/form-data" } }, undefined, 60000).post(
      `/v1/voucher/import?companyId=${companyId}`,
      body,
    );
  }
}

export default APIService;
